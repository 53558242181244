<template>
    <div id="refund-notification">
        <div
            class="modal fade"
            id="refundNotificationPopup"
            tabindex="-1"
            role="dialog"
            aria-labelledby="refundNotificationPopupTitle"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="refundNotificationPopupTitle">
                            <strong>
                                Thông báo hoàn tiền
                            </strong>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span class="material-icons" style="font-weight: bold">
                                close
                            </span>
                        </button>
                    </div>
                    <div class="modal-body">
                        {{ refundMessage }}
                    </div>
                    <div class="modal-footer">
                        <button @click="moToRefund" type="button" class="btn btn-primary" data-dismiss="modal">
                            Quản lý hoàn tiền
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getRefundCount } from "@/api/admin"

export default {
    name: "RefundNotification",
    data: function() {
        return {
            refundMessage: ""
        }
    },
    created() {
        this.getRefundCount()
    },
    methods: {
        moToRefund: function() {
            this.$router.push("/refund")
            this.updatePopupStatus()
        },
        updatePopupStatus: function() {
            this.$store.commit("SET_POPUP_STATUS", true)
        },
        getRefundCount: async function() {
            const response = await getRefundCount()
            if (response.success) {
                this.refundMessage = response.message
            }
        }
    },
    computed: {
        refund_popup() {
            return this.$store.getters.getPopupStatus
        },
        userInfo() {
            return this.$store.getters.getUser
        }
    },
    mounted() {
        if (this.refund_popup === false && this.userInfo.role === "admin") {
            $("#refundNotificationPopup").modal("show")
        }
        const _this = this
        $("#refundNotificationPopup").on("hidden.bs.modal", function() {
            _this.updatePopupStatus()
        })
    },
    watch: {
        refundMessage: function(value) {
            if (value && value !== "" && this.refund_popup === false && this.userInfo.role === "admin") {
                window.toastr.options.closeHtml = '<button><i class="icon-off"></i></button>'
                window.toastr.options.timeOut = 10000
                this.$toastr.success(value)
            }
        }
    }
}
</script>
