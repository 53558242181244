<template>
    <div>
        <div class="row">
            <div v-if="!isMobile" class="col-md-6 col-12">
                <div
                    class="row mb-2"
                    v-show="index > 1 && !item.is_hidden"
                    v-for="(item, index) in menusManipulated"
                    :key="index"
                >
                    <div class="container">
                        <h4 class="mb-0 bold text-uppercase">{{ item.text }}</h4>
                        <div class="row">
                            <div
                                class="col-xl-3 col-lg-4 col-6 px-1 mt-2"
                                v-show="(!child.admin || user.role === 'admin') && !child.is_hidden"
                                v-for="(child, indexs) in item.childs"
                                :key="indexs"
                            >
                                <router-link :to="child.to" class="router" aria-expanded="false" v-if="index != 2">
                                    <a class="btn-menu p-2">
                                        <svg>
                                            <rect x="0" y="0" fill="none" width="100%" height="100%" />
                                        </svg>
                                        <img
                                            v-if="child.tool_alias"
                                            class="group-icon"
                                            :src="'assets/icons/menu-icon/' + child.tool_alias + '.png'"
                                            alt="group-icon"
                                            width="39"
                                            height="39"
                                        />
                                        <h6 class="font-13 mt-3 mb-0">{{ child.text }}</h6>
                                    </a>
                                </router-link>
                                <router-link :to="child.childs[0].to" class="router" aria-expanded="false" v-else>
                                    <a class="btn-menu p-2">
                                        <svg>
                                            <rect x="0" y="0" fill="none" width="100%" height="100%" />
                                        </svg>
                                        <img
                                            v-if="child.childs[0].tool_alias"
                                            class="group-icon"
                                            :src="'assets/icons/menu-icon/' + child.childs[0].tool_alias + '.png'"
                                            alt="group-icon"
                                            width="39"
                                            height="39"
                                        />
                                        <h6 class="font-13 mt-3 mb-0">{{ child.text }}</h6>
                                    </a>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div v-if="admin_notification && admin_notification.title">
                    <h4 class="bold text-uppercase">Thông báo</h4>
                    <div class="card card-info mt-2">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-9 pr-0">
                                    <h5 class="bold text-uppercase">{{ admin_notification.title }}</h5>
                                    <h6 v-html="admin_notification.content"></h6>
                                </div>
                                <div class="col-3 pl-0 text-right">
                                    <img
                                        :src="'/assets/images/notification/notification.png'"
                                        alt="group-icon"
                                        width="50%"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    :class="['card hand total-coin mb-2', admin_notification && admin_notification.title ? 'mt-3' : '']"
                >
                    <div @click="$router.push('user-payment')" class="card-body py-md-1 py-2">
                        <div class="row align-items-center">
                            <div class="col-5">
                                <img
                                    class="group-icon"
                                    :src="'/assets/images/support/payment.png'"
                                    alt="group-icon"
                                    width="90%"
                                />
                            </div>
                            <div class="col">
                                <h4 :class="['bold text-uppercase text-center mb-0']">
                                    NẠP TIỀN VÀO TÀI KHOẢN
                                </h4>
                            </div>
                            <div class="col-auto text-right pr-1">
                                <h4 :class="['bold text-uppercase mb-0']">
                                    <i class="fas fa-angle-double-right"></i>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 :class="['bold text-uppercase']">báo cáo</h4>
                <div class="row mt-2">
                    <div class="col-md-4 mb-3 col-12">
                        <div class="card total-coin h-100">
                            <div class="card-body py-2 text-center px-1">
                                <h6 class="font-bold mb-0">TỔNG TIỀN</h6>
                                <h5 class="bold mb-0">
                                    <number
                                        ref="number1"
                                        :from="0"
                                        :to="user.coin"
                                        :format="theFormat"
                                        :duration="1"
                                        :delay="0"
                                        easing="Power1.easeOut"
                                    />
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-3 col-6">
                        <div class="card card-month h-100">
                            <div class="card-body py-2 text-center px-1">
                                <h6 class="font-bold mb-0">THÁNG NÀY</h6>
                                <h5 class="bold mb-0">
                                    <number
                                        ref="number1"
                                        :from="0"
                                        :to="used"
                                        :format="theFormat"
                                        :duration="1"
                                        :delay="0"
                                        easing="Power1.easeOut"
                                    />
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-3 col-6">
                        <div class="card card-info h-100">
                            <div class="card-body py-2 text-center px-1">
                                <h6 class="font-bold mb-0">TỔNG NẠP</h6>
                                <h5 class="bold mb-0">
                                    <number
                                        ref="number1"
                                        :from="0"
                                        :to="paid"
                                        :format="theFormat"
                                        :duration="1"
                                        :delay="0"
                                        easing="Power1.easeOut"
                                    />
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <statistics></statistics>
                <h4 class="bold text-uppercase mt-3">HỖ TRỢ</h4>
                <div class="card card-support my-2">
                    <div class="card-body py-2 pr-0">
                        <div class="row align-items-center">
                            <div class="col-7">
                                <h5 class="bold mb-0">LIÊN HỆ HỖ TRỢ</h5>
                                <hr class="py-0" />
                                <a :href="site.facebook" target="_blank" class="row align-items-center">
                                    <div class="col-auto">
                                        <img
                                            class="group-icon block-text"
                                            :src="'/assets/images/support/facebook.png'"
                                            alt="group-icon"
                                            width="30px"
                                        />
                                    </div>
                                    <div class="col block-text pl-0 text-white">
                                        <h5 class="block-text mb-0">{{ site.facebook }}</h5>
                                    </div>
                                </a>
                                <a :href="'tel:' + site.mobile_phone" class="row align-items-center mt-2">
                                    <div class="col-auto">
                                        <img
                                            class="group-icon block-text"
                                            :src="'/assets/images/support/phone.png'"
                                            alt="group-icon"
                                            width="30px"
                                        />
                                    </div>
                                    <div class="col block-text pl-0 text-white">
                                        <h5 class="block-text mb-0">{{ site.mobile_phone }}</h5>
                                    </div>
                                </a>
                            </div>
                            <div class="col-5 text-right">
                                <img
                                    class="group-icon"
                                    :src="'/assets/images/support/support.png'"
                                    alt="group-icon"
                                    width="80%"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isMobile" class="col-md-6 col-12">
                <div
                    class="row mb-2"
                    v-show="index != 0 && !item.is_hidden"
                    v-for="(item, index) in menusManipulated"
                    :key="index"
                >
                    <div class="container">
                        <h4 class="bold text-uppercase mb-0">{{ item.text }}</h4>
                        <div class="row">
                            <div
                                class="col-xl-3 col-4 px-1 mt-2"
                                v-show="(!child.admin || user.role === 'admin') && !child.is_hidden"
                                v-for="(child, indexs) in item.childs"
                                :key="indexs"
                            >
                                <router-link :to="child.to" class="router" aria-expanded="false">
                                    <a class="btn-menu p-2">
                                        <svg>
                                            <rect x="0" y="0" fill="none" width="100%" height="100%" />
                                        </svg>
                                        <img
                                            v-if="child.tool_alias"
                                            class="group-icon"
                                            :src="'assets/icons/menu-icon/' + child.tool_alias + '.png'"
                                            alt="group-icon"
                                            width="33"
                                            height="33"
                                        />
                                        <h6 class="font-12 mt-3 mb-0">{{ child.text }}</h6>
                                    </a>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <refund-notification />
    </div>
</template>
<script>
import menus from "@/config/menu"
import statistics from "./home-statistics"
import moment from "moment"
import { getPaymentMonth } from "../../api/payment"
import refundNotification from "./refund-notification.vue"
export default {
    name: "home",
    components: {
        statistics,
        refundNotification
    },
    data() {
        return {
            menus: menus,
            used: 0,
            paid: 0
        }
    },
    computed: {
        menusManipulated() {
            const results = []
            this.menus.forEach(menu => {
                if (menu.id !== "facebook-auto") {
                    results.push(menu)
                } else {
                    for (let i = 0; i < menu.childs.length; i++) {
                        results.push(menu.childs[i])
                    }
                }
            })
            const remaining = results.filter(obj => {
                return obj.id !== "facebook-auto"
            })
            return remaining
        },
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        totalDayUse() {
            return moment().diff(this.user.created_at, "days")
        },
        site() {
            return this.$store.state.site.site
        },
        tools() {
            return this.$store.state.tools.tools
        },
        admin_notification() {
            let noti = null
            if (this.site && this.site.admin_notification) {
                try {
                    noti = JSON.parse(this.site.admin_notification)
                    noti.content = noti.content.replace(/\n/g, "<br>")
                } catch (error) {
                    noti = null
                }
            }
            return noti
        },
        refund_popup() {
            return this.$store.getters.getPopupStatus
        }
    },
    created() {
        this.getPayment()
        this.menuConfig()
    },
    methods: {
        getPayment: async function() {
            let data = await getPaymentMonth()
            if (data.success) {
                this.used = data.used
                this.paid = data.paid
            }
        },
        async showMenu(item) {
            this.menus = this.menus.map(menu => {
                if (item === menu) {
                    menu.show = !menu.show
                } else menu.show = false
                return menu
            })
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                : 0
        },
        menuConfig: function() {
            let tools = this.tools
            this.menus = this.menus.map(menu => {
                if (menu.childs) {
                    let countHide = 0
                    let countMaintenance = 0
                    menu.childs.map(child => {
                        if (child.name) {
                            let toolName = child.name
                            let currentTool = tools.find(item => {
                                return item.name === toolName
                            })
                            if (!currentTool) {
                                toolName = child.tool_alias
                                currentTool = this.tools.find(item => {
                                    return item.tool_alias === toolName || item.package_name === toolName
                                })
                            }
                            if (currentTool) {
                                child.is_hidden = currentTool.status === -1 ? true : currentTool.is_hidden
                                if (child.is_hidden) {
                                    countHide++
                                }
                                if (currentTool.status === 0) {
                                    child.isMaintenance = true
                                    countMaintenance++
                                }
                                child.notes = currentTool.notes
                            }
                        }
                        return child
                    })
                    if (menu.childs.length === countHide) {
                        menu.is_hidden = true
                    } else menu.is_hidden = false
                    if (menu.childs.length === countMaintenance) {
                        menu.isMaintenance = true
                    } else menu.isMaintenance = false
                }
                if (menu.name) {
                    let toolName = menu.name
                    let currentTool = tools.find(item => {
                        return item.name === toolName
                    })
                    if (currentTool) {
                        if (currentTool.status === -1) {
                            menu.hide = true
                        }
                        if (currentTool.status === 0) {
                            menu.isMaintenance = true
                        }
                        menu.notes = currentTool.notes
                    }
                }
                return menu
            })
        }
    }
}
</script>
