<template>
    <div>
        <div :class="['row my-2 align-items-center']">
            <div class="col-md"></div>
            <div class="col-md-auto">
                <div class="card card-gray">
                    <div class="card-body py-0 px-2">
                        <div class="row">
                            <div class="col-md-auto col-6 my-2">
                                <h6 class="font-bold mb-0"><i class="fas fa-calendar-alt"></i></h6>
                            </div>
                            <div class="col-md-auto col-6 my-2">
                                <datetime
                                    class="theme-green"
                                    type="date"
                                    v-model="startDate"
                                    format="dd/MM/yyyy"
                                    input-class="text-center input-light"
                                    value-zone="Asia/Ho_Chi_Minh"
                                ></datetime>
                            </div>
                            <div class="col-md-auto col-6 my-2">
                                <h6 class="font-bold mb-0">đến</h6>
                            </div>
                            <div class="col-md-auto col-6 my-2">
                                <datetime
                                    class="theme-green"
                                    type="date"
                                    v-model="endDate"
                                    format="dd/MM/yyyy"
                                    input-class="text-center input-light"
                                    :min-datetime="startDate"
                                    value-zone="Asia/Ho_Chi_Minh"
                                ></datetime>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <sales :chart-data="chartData"></sales>
        <h5 class="text-center mt-2 mb-0">
            <span class="bold mr-3 green"
                >Tổng nạp:
                <number
                    ref="number1"
                    :from="0"
                    :to="total_paid"
                    :format="theFormat"
                    :duration="1"
                    :delay="0"
                    easing="Power1.easeOut"/></span
            ><span class="bold orange"
                >Tổng chi:
                <number
                    ref="number1"
                    :from="0"
                    :to="total_used"
                    :format="theFormat"
                    :duration="1"
                    :delay="0"
                    easing="Power1.easeOut"
            /></span>
        </h5>
    </div>
</template>

<script>
import { getStatistics } from "../../api/payment"
import moment from "moment"
import sales from "../../components/chart/sales-chart"
export default {
    name: "Statistics",
    components: {
        sales
    },
    data() {
        return {
            startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
            endDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1).toISOString(),
            chartData: {},
            data: [],
            total_used: 0,
            total_paid: 0,
            data_result: [],
            load_data: false
        }
    },
    watch: {
        startDate() {
            if (!this.load_data) {
                this.getPayment()
            }
        },
        endDate() {
            if (!this.load_data) {
                this.getPayment()
            }
        }
    },
    async created() {
        this.load_data = true
        await this.getPayment()
        this.load_data = false
    },
    methods: {
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                : 0
        },
        returnDate: function(myDate) {
            myDate = new Date(myDate)
            return moment(myDate).format("YYYY-MM-DD")
        },
        getPayment: async function() {
            let data = await getStatistics(this.returnDate(this.startDate), this.returnDate(this.endDate))
            if (data.success) {
                this.data = data.data
                this.total_used = data.total_used
                this.total_paid = data.total_paid
                this.createChart()
            } else {
                if (data.message) {
                    this.$swal("Lỗi", data.message, "error")
                } else {
                    this.$swal("Lỗi", JSON.stringify(data), "error")
                }
            }
        },
        createChartData: function() {
            let labelArray = []
            let labelUsed = []
            let labelPaid = []
            for (const data of this.data) {
                for (const prop in data) {
                    labelArray.push(moment(prop).format("DD-MM-YYYY"))
                    labelPaid.push(data[prop].paid)
                    labelUsed.push(data[prop].used)
                }
            }
            return { labelPaid, labelUsed, labelArray }
        },
        createChart: async function() {
            this.chartData = {}
            let chartData = this.createChartData()
            this.chartData = {
                labels: chartData.labelArray,
                datasets: [
                    {
                        type: "bar",
                        label: "Tiền nạp",
                        backgroundColor: "#1FAB89",
                        hoverBackgroundColor: "#1FAB89",
                        borderWidth: 0,
                        data: chartData.labelPaid
                    },
                    {
                        type: "bar",
                        label: "Tiền chi",
                        backgroundColor: "#FFB900",
                        hoverBackgroundColor: "#FFB900",
                        borderWidth: 0,
                        data: chartData.labelUsed
                    }
                ]
            }
        },
        selectFilter: function(item) {
            this.filters = item
        }
    }
}
</script>

<style scoped></style>
